/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FastDreamLocation } from '../models/FastDreamLocation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FastDreamLocationService {
  /**
   * Retrieves the collection of FastDreamLocation resources.
   * Retrieves the collection of FastDreamLocation resources.
   * @param id FastDreamLocation identifier
   * @returns FastDreamLocation FastDreamLocation collection
   * @throws ApiError
   */
  public static collectionLocations(
    id: string,
  ): CancelablePromise<Array<FastDreamLocation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/collections/{name}/locations',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Retrieves the collection of FastDreamLocation resources.
   * Retrieves the collection of FastDreamLocation resources.
   * @param id FastDreamLocation identifier
   * @returns FastDreamLocation FastDreamLocation collection
   * @throws ApiError
   */
  public static dreamLocations(
    id: string,
  ): CancelablePromise<Array<FastDreamLocation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dreams/{id}/locations',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Creates a FastDreamLocation resource.
   * Creates a FastDreamLocation resource.
   * @param id FastDreamLocation identifier
   * @param requestBody The new FastDreamLocation resource
   * @returns FastDreamLocation FastDreamLocation resource created
   * @throws ApiError
   */
  public static addDreamLocation(
    id: string,
    requestBody: Partial<FastDreamLocation>,
  ): CancelablePromise<FastDreamLocation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/dreams/{id}/locations',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Replaces the FastDreamLocation resource.
   * Replaces the FastDreamLocation resource.
   * @param id FastDreamLocation identifier
   * @param locationId FastDreamLocation identifier
   * @param requestBody The updated FastDreamLocation resource
   * @returns FastDreamLocation FastDreamLocation resource updated
   * @throws ApiError
   */
  public static updateDreamLocation(
    id: string,
    locationId: string,
    requestBody: Partial<FastDreamLocation>,
  ): CancelablePromise<FastDreamLocation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/dreams/{id}/locations/{locationId}',
      path: {
        'id': id,
        'locationId': locationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input`,
        404: `Resource not found`,
        422: `Unprocessable entity`,
      },
    });
  }
  /**
   * Removes the FastDreamLocation resource.
   * Removes the FastDreamLocation resource.
   * @param id FastDreamLocation identifier
   * @param locationId FastDreamLocation identifier
   * @returns void
   * @throws ApiError
   */
  public static deleteDreamLocation(
    id: string,
    locationId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/dreams/{id}/locations/{locationId}',
      path: {
        'id': id,
        'locationId': locationId,
      },
      errors: {
        404: `Resource not found`,
      },
    });
  }
  /**
   * Retrieves the collection of FastDreamLocation resources.
   * Retrieves the collection of FastDreamLocation resources.
   * @returns FastDreamLocation FastDreamLocation collection
   * @throws ApiError
   */
  public static dreamMap(): CancelablePromise<Array<FastDreamLocation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/map',
    });
  }
  /**
   * Retrieves the collection of FastDreamLocation resources.
   * Retrieves the collection of FastDreamLocation resources.
   * @param id FastDreamLocation identifier
   * @returns FastDreamLocation FastDreamLocation collection
   * @throws ApiError
   */
  public static userLocations(
    id: string,
  ): CancelablePromise<Array<FastDreamLocation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/users/{id}/locations',
      path: {
        'id': id,
      },
    });
  }
}
